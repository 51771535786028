<div *ngIf="frameError && !frameLoading" class="frame-container-error">
  <h2>This page could not be loaded</h2>
  <span>Contact the administrator</span>
</div>
<div *ngIf="frameLoading" class="frame-container-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
<div *ngIf="frameSrc" class="frame-container" [class.frame-container-loading]="frameLoading">
  <iframe *ngIf="frameType == 'custom'" id="" [src]="frameSafeSrc | safe" (load)="onFrameLoad()" (error)="onFrameError()" allow="clipboard-write"></iframe>
  <iframe *ngIf="frameType != 'custom'"  id="" [src]="frameSafeSrc | safe" (load)="onFrameLoad()" (error)="onFrameError()"></iframe>
</div>