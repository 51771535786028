import { Component, OnInit, ElementRef } from '@angular/core'
import { ROUTES } from '../sidebar/sidebar.component'
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from '@angular/common'
import { Router } from '@angular/router'
import { AuthService } from 'app/services/auth.service'
import { CookieService } from 'ngx-cookie-service'
import { catchError, throwError } from 'rxjs'
import { MenuService } from 'app/services/menu.service'

@Component({
  // moduleId: module.id,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  private listTitles: any[]
  location: Location
  private toggleButton: any
  private sidebarVisible: boolean
  private sidebarCollapsed: boolean
  logoUrl
  parentLogoUrl
  dataColor: string
  navbarBackgroundColor: string

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private authService: AuthService,
    private cookies: CookieService,
    private menuService: MenuService
  ) {
    this.location = location
    this.sidebarVisible = false
    this.sidebarCollapsed = false
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle)
    this.logoUrl = localStorage.getItem('homeLogoUrl')
    this.parentLogoUrl = localStorage.getItem('parentLogoUrl')
    const navbar: HTMLElement = this.element.nativeElement
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0]
    this.dataColor = localStorage.getItem('theme')
    this.navbarBackgroundColor = this.dataColor == 'grey' ? '#535362' : '#000000'
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton
    const body = document.getElementsByTagName('body')[0]
    setTimeout(function () {
      toggleButton.classList.add('toggled')
    }, 500)
    body.classList.add('nav-open')

    this.sidebarVisible = true
  }

  onLogOut() {
    this.authService
      .logout(this.cookies.get('at'))
      .pipe(
        catchError((e) => {
          if (e.status === 'Access Token has been revoked') {
            this.cookies.delete('at')
            this.cookies.delete('rt')
            this.cookies.delete('it')
            const langs = localStorage.getItem('langs')
            localStorage.clear()
            localStorage.setItem('langs', langs)
            this.router.navigate(['login'])
          } else {
            return throwError(() => new Error('Unexpected error!'))
          }
        })
      )
      .subscribe(() => {
        this.authService.clearSession()
      })
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0]
    this.toggleButton.classList.remove('toggled')
    this.sidebarVisible = false
    body.classList.remove('nav-open')
  }

  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen()
    } else {
      this.sidebarClose()
    }
  }

  sidebarCollapse() {
    if (this.sidebarCollapsed === false) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('sidebar-collapsed');
      const sidebar_logo = document.getElementsByClassName('domain-logo-img')[0] as HTMLImageElement;
      sidebar_logo.src = "assets/img/vx-anagrama.png"; 
      const navbar_icon = document.getElementsByClassName('navbar-toggle-size')[0] as HTMLInputElement;
      navbar_icon.innerHTML = "<i class='pe-7s-angle-right'></i>";
      this.sidebarCollapsed = true
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('sidebar-collapsed');
      const sidebar_logo = document.getElementsByClassName('domain-logo-img')[0] as HTMLImageElement;
      sidebar_logo.src = "assets/img/vx-management-logo.png";
      const navbar_icon = document.getElementsByClassName('navbar-toggle-size')[0] as HTMLInputElement;
      navbar_icon.innerHTML = "<i class='pe-7s-angle-left'></i>";
      this.sidebarCollapsed = false;
    }
  }

  getTitle() {
    let title = this.location.prepareExternalUrl(this.location.path())

    if (title.charAt(0) === '#') {
      title = title.slice(1)
    }

    // TODO: Find a better solution.
    if (title.split('/').includes('users')) {
      return this.menuService.getScreen('USERS').name
    }

    if (title === '/negotiation/CPI') {
      return this.menuService.getScreen('CPI').name
    }

    if (title === '/negotiation/NEGV') {
      return this.menuService.getScreen('NEGV').name
    }

    if (title === '/negotiationajiles/CPIAJILES') {
      return this.menuService.getScreen('CPIAJILES').name
    }

    if (title === '/negotiationajiles/NEGVAJILES') {
      return this.menuService.getScreen('NEGVAJILES').name
    }

    if (title === '/negotiation/PREV') {
      return this.menuService.getScreen('PREV').name
    }

    if (title === '/negotiation/NRSUM') {
      return this.menuService.getScreen('NRSUM').name
    }

    if (title === '/alignment/ALGS') {
      return this.menuService.getScreen('ALGS').name
    }

    if (title === '/alignment/ALGSD') {
      return this.menuService.getScreen('ALGSD').name
    }

    if (title === '/onboarding/ONBGS') {
      return this.menuService.getScreen('ONBGS').name
    }

    if (title === '/onboarding/ONBGSD') {
      return this.menuService.getScreen('ONBGSD').name
    }

    if (title === '/onboarding/RETGS') {
      return this.menuService.getScreen('RETGS').name
    }

    if (title === '/onboarding/RETGSD') {
      return this.menuService.getScreen('RETGSD').name
    }

    if (title === '/customer/CUSTS') {
      return this.menuService.getScreen('CUSTS').name
    }

    if (title === '/customer/CUSTOMERAGENT') {
      return this.menuService.getScreen('CUSTOMERAGENT').name
    }

    const currentScreen = this.listTitles.find(
      (screen) => screen.path === title
    )

    if (currentScreen !== undefined) {
      return this.menuService.getScreen(currentScreen.code)?.name
    }

    return null
  }

  getTitleIcon() {
    let title = this.location.prepareExternalUrl(this.location.path())

    if (title.charAt(0) === '#') {
      title = title.slice(1)
    }

    // TODO: Find a better solution.
    if (title.split('/').includes('users')) {
      return this.menuService.getScreen('USERS').icon
    }

    if (title === '/negotiation/CPI') {
      return this.menuService.getScreen('CPI').icon
    }

    if (title === '/negotiation/NEGV') {
      return this.menuService.getScreen('NEGV').icon
    }

    if (title === '/negotiationajiles/CPIAJILES') {
      return this.menuService.getScreen('CPIAJILES').icon
    }

    if (title === '/negotiationajiles/NEGVAJILES') {
      return this.menuService.getScreen('NEGVAJILES').icon
    }

    if (title === '/negotiation/PREV') {
      return this.menuService.getScreen('PREV').icon
    }

    if (title === '/negotiation/NRSUM') {
      return this.menuService.getScreen('NRSUM').icon
    }

    if (title === '/alignment/ALGS') {
      return this.menuService.getScreen('ALGS').icon
    }

    if (title === '/alignment/ALGSD') {
      return this.menuService.getScreen('ALGSD').icon
    }

    if (title === '/onboarding/ONBGS') {
      return this.menuService.getScreen('ONBGS').icon
    }

    if (title === '/onboarding/ONBGSD') {
      return this.menuService.getScreen('ONBGSD').icon
    }

    if (title === '/onboarding/RETGS') {
      return this.menuService.getScreen('RETGS').icon
    }

    if (title === '/onboarding/RETGSD') {
      return this.menuService.getScreen('RETGSD').icon
    }

    if (title === '/customer/CUSTS') {
      return this.menuService.getScreen('CUSTS').icon
    }

    if (title === '/customer/CUSTOMERAGENT') {
      return this.menuService.getScreen('CUSTOMERAGENT').icon
    }

    const currentScreen = this.listTitles.find(
      (screen) => screen.path === title
    )

    if (currentScreen !== undefined) {
      const icon = this.menuService.getScreen(currentScreen.code)?.icon

      return icon
    }

    return null
  }

  getTitleDocumentation() {
    const title = this.location.prepareExternalUrl(this.location.path())

    let documentation_link = ''

    if (
      title === '/negotiation/CPI' ||
      title === '/negotiation/NEGV' ||
      title === '/negotiation/PREV' ||
      title === '/negotiation/NRSUM' ||
      title === '/negotiationajiles/NEGVAJILES' ||
      title === '/negotiationajiles/CPIAJILES'
    ) {
      documentation_link =
        'assets/docs/VX-Management - User Guide - Negotiation Report V.1.pdf'
    }

    if (title === '/matchingTool') {
      documentation_link =
        'assets/docs/VX-Management - User Guide - Matching Tool V.1.pdf'
    }

    if (title === '/translationTool') {
      documentation_link =
        'assets/docs/VX-Management - User Guide - Translation Tool V.1.pdf'
    }

    if (title === '/onboarding/ONBGSD' ||
      title === '/onboarding/ONBGS' ||
      title === '/onboarding/RETGS' ||
      title === '/onboarding/RETGSD'
    ) {
      documentation_link =
        'assets/docs/VX-Management - User Guide - Onboarding V.1.pdf'
    }

    if (title === '/customer/CUSTS' ||
      title === '/customer/CUSTOMERAGENT'
    ) {
      documentation_link =
        'assets/docs/VX-Management - User Guide - Customers V.2.pdf'
    }
    if(title === '/commissions'){
      documentation_link =
        'assets/docs/VX-Management - User Guide - Commissions V.4.pdf'
    }

    return documentation_link
  }
}
