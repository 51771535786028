<div>
  <div [id]="reportId" class="report-dashboard-container"></div>
  <div *ngIf="loadingDashboard" class="report-dashboard-spinner">
    <mat-spinner color="primary"></mat-spinner>
  </div>
  <div *ngIf="!loadingDashboard && errorDashboard" class="report-dashboard-error">
      <h2>Report could not be loaded</h2>
      <span>Contact the administrator</span>
  </div>
  <div 
    class="watermark-overlay" 
    [ngClass]="themeClass">
    VX Smart Management © 2024
  </div>
</div>
