import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit {
  @Input() frameSrc!: string
  @Input() frameType: string
  frameError = false
  frameLoading = true
  frameSafeSrc

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.frameSafeSrc = this.frameSrc
    this.cdr.detectChanges()
  }

  onFrameLoad() {
    this.frameLoading = false
  }

  onFrameError() {
    this.frameError = true
    this.frameLoading = false
  }
}
