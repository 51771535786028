import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { catchError, EMPTY } from 'rxjs'
import { createEmbeddingContext, DashboardContentOptions } from 'amazon-quicksight-embedding-sdk'

import { MenuService } from 'app/services/menu.service'

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  constructor(private http: HttpClient, private menuService: MenuService) {}

  @Input() reportId!: string
  @Input() screen!: string
  @Input() submenu = false
  @Input() params: object
  loadingDashboard = true
  errorDashboard = false
  themeClass: string;

  ngOnInit(): void {
    const report_url = !this.submenu
      ? this.menuService.getScreen(this.screen)?.url
      : this.menuService.getScreen(this.screen, true)?.url

    const vxtheme = localStorage.getItem('theme') === 'grey' ? 'light' : 'dark';
    this.themeClass = vxtheme === 'light' 
      ? 'watermark-overlay-light-theme' 
      : 'watermark-overlay-dark-theme';

    this.http
      .get<string>(report_url+"?permissioncode="+this.screen)
      .pipe(
        catchError(() => {
          this.loadingDashboard = false
          this.errorDashboard = true

          return EMPTY
        })
      )
      .subscribe((url) => this.loadDashboard(url))
  }

  private async loadDashboard(url) {

    const embeddingContext = await createEmbeddingContext();

    const {
      embedDashboard
    } = embeddingContext;

    const options = {
      url,
      container: document.getElementById(this.reportId),
      undoRedoDisabled: true,
      resetDisabled: true
    }

    var contentOptions: DashboardContentOptions = {  
        toolbarOptions: {
          export: true,
          
      },
      attributionOptions: {
          overlayContent: false,
      },
    }
    var vxtheme = localStorage.getItem('theme') =='grey'? 'light' : 'dark';
    
    // CLASSIC -> theme light
    if(vxtheme == 'light'){
      contentOptions = {  
        ...contentOptions,
        themeOptions: {
          themeArn: "arn:aws:quicksight::aws:theme/CLASSIC"
        },
        parameters: [
          {
              Name: "themename",
              Values: [vxtheme]
          }
        ]
      }
    }

    var embebeddashboard = await embedDashboard(options, contentOptions);

    this.loadingDashboard = false
    this.errorDashboard = false
  }
}
